import React from "react";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import { useEffect } from 'react';

function Home() {
  useEffect(() => {
    // Initialize PureCounter after the component is mounted
    if (window.PureCounter) {
      new window.PureCounter(); // Initialize the pure counter
    }

  }, []); // This runs only after the component is mounted

  return (
    <>
      <Header />
      <div
        data-elementor-type="wp-page"
        data-elementor-id={162}
        className="elementor elementor-162"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-a112ede elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="a112ede"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-81dbddd"
              data-id="81dbddd"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-eeaf2b1 elementor-widget elementor-widget-slider bdevs-el slider"
                  data-id="eeaf2b1"
                  data-element_type="widget"
                  data-settings='{"design_style":"style_1"}'
                  data-widget_type="slider.default"
                >
                  <div className="elementor-widget-container">
                    <section className="slider-area fix">
                      <div
                        className="slider-active slider-s-animation swiper-container"
                        data-swipper_autoplay_stop="no"
                      >
                        <div className="swiper-wrapper">
                          <div
                            className="single-slider slider-height d-flex align-items-center swiper-slide"
                            data-swiper-autoplay=""
                          >
                            <div
                              className="slide-bg"
                              data-background="assets/wp-content/uploads/2022/02/ex-video-1024x521.jpg"
                            />
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="z-index text-center bdevs-el-content">
                                    {/* <span
                                    className="slider-top-text bdevs-el-subtitle"
                                    data-animation="fadeInUp"
                                    data-delay=".5s"
                                  >
                                    Since 1990
                                  </span>
                                  <div
                                    className="aslider--title-wrapper"
                                    data-animation="fadeInUp"
                                    data-delay=".7s"
                                  >
                                    <h2 className="aslider--title aslider--title-bg mb-30 bdevs-el-title">
                                      Discover Service
                                    </h2>
                                  </div> */}
                                    <p
                                      className="aslider--subtitle"
                                      data-animation="fadeInUp"
                                      data-delay=".9s"
                                    >
                                      Building <span style={{ color: "yellow", fontWeight: '600' }}>Success</span> through People, One Hire at a Time{" "}
                                    </p>
                                    <p  style={{color:'white'}}>We take pride in building strong, capable teams for businesses across industries. Our carefully selected workforce empowers you to focus on what matters—growing your business.</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="single-slider slider-height d-flex align-items-center swiper-slide"
                            data-swiper-autoplay=""
                          >
                            <div
                              className="slide-bg"
                              data-background="assets/wp-content/uploads/2022/02/slider-bg-2.jpg"
                            />
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="z-index text-center bdevs-el-content">
                                    {/* <span
                                    className="slider-top-text bdevs-el-subtitle"
                                    data-animation="fadeInUp"
                                    data-delay=".5s"
                                  >
                                    Since 1990
                                  </span>
                                  <div
                                    className="aslider--title-wrapper"
                                    data-animation="fadeInUp"
                                    data-delay=".7s"
                                  >
                                    <h2 className="aslider--title aslider--title-bg mb-30 bdevs-el-title">
                                      Discover Service
                                    </h2>
                                  </div> */}
                                    <p
                                      className="aslider--subtitle"
                                      data-animation="fadeInUp"
                                      data-delay=".9s"
                                    >
                                      Building <span style={{ color: "yellow", fontWeight: '600' }}>Success</span> through People, One Hire at a Time{" "}
                                    </p>
                                    <p style={{color:'white'}}>We take pride in building strong, capable teams for businesses across industries. Our carefully selected workforce empowers you to focus on what matters—growing your business.</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* If we need navigation buttons */}
                        <div className="swiper-button-prev slide-prev">
                          <i className="far fa-long-arrow-left" />
                        </div>
                        <div className="swiper-button-next slide-next">
                          <i className="far fa-long-arrow-right" />
                        </div>
                        <div className="swiper-paginations megamau-paginate-1" />
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-1c1efd3 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="1c1efd3"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e51db8e"
              data-id="e51db8e"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-c0f067b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="c0f067b"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-default">
                    <div
                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-e27482c"
                      data-id="e27482c"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-497661a elementor-widget elementor-widget-heading bdevs-el heading"
                          data-id="497661a"
                          data-element_type="widget"
                          data-settings='{"design_style":"style_1"}'
                          data-widget_type="heading.default"
                        >
                          <div className="elementor-widget-container">
                            <div
                              className="section_title_wrapper mgsection_title-bottoms  wow fadeInUp"
                              data-wow-delay="0.3s"
                            >
                              {/* <span className="subtitle bdevs-el-subtitle">
                                Service Achievement{" "}
                              </span> */}
                              <h2 className="section-title section-title-black bdevs-el-title">
                                Our Mission{" "}
                              </h2>
                              <span>
                                Our mission is to simplify staffing and workforce management, delivering tailored solutions that help companies grow, innovate, and succeed. And give the opportunity to the Indian professional workforce from Skilled, Technical to Unskilled workers to work with innovative companies.{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div
                  className="elementor-element elementor-element-0509cdf elementor-widget elementor-widget-service bdevs-el service"
                  data-id="0509cdf"
                  data-element_type="widget"
                  data-settings='{"design_style":"style_3"}'
                  data-widget_type="service.default"
                >
                  <div className="elementor-widget-container">
                    <section className="service-achievement">
                      <div className="container">
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6">
                            <div
                              className="sr-achievement__thumb d-flex p-relative mb-30 wow fadeInUp"
                              data-wow-delay="0.3s"
                            >
                              <div className="sr-achievement__thumb-left mr-20">
                                <div className="sr-achievement__thumb-left-img1 mb-20">
                                  <img
                                    decoding="async"
                                    src="assets/images/service_Photo/7.jpeg"
                                    // src="assets/images/service_Photo/about-n1.png"
                                    // src="assets/wp-content/uploads/2022/02/achi-1.jpg"
                                    alt="image not found"
                                    style={{ height: '250px', width: '250px' }}
                                  />
                                </div>
                                <div className="sr-achievement__thumb-left-img2">
                                  <img
                                    decoding="async"
                                    src="assets/images/service_Photo/12.jpg"
                                    alt="image not found"
                                    style={{ height: '250px', width: '250px' }}
                                  />
                                </div>
                              </div>
                              <div className="sr-achievement__thumb-right">
                                <div className="sr-achievement__thumb-right-img1">
                                  <img
                                    decoding="async"
                                    src="assets/images/service_Photo/13.jpg"
                                    alt="image not found"
                                    style={{ height: '520px', width: '250px' }}
                                  />
                                </div>
                              </div>
                              <div className="achievement-batch">
                                <img
                                  decoding="async"
                                  src="assets/images/service_Photo/14.png"
                                  alt="image not found"
                                  style={{
                                    height: '250px', width: '250px',
                                    borderRadius: "50%", // Makes it circular
                                    overflow: "hidden", // Ensures no content overflows
                                    objectFit: "cover", // Ensures the image fits well
                                    border: "2px solid #000", // Optional: Add a border
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6">
                            <div className="business-quality-group" style={{ textAlign: 'justify' }}>
                              {/* ml-30 pr-100 */}
                              <div className="business-quality wow fadeInUp" data-wow-delay="0.3s">
                                <div className="success__single d-flex megamau-success-single-3 mb-30">
                                  <div className="success__single-icon2 mt-10 mr-35">
                                    <a href="#">
                                      <i className="bdevs-btn-icon fal fa-trophy-alt" aria-hidden="true" />{" "}
                                    </a>
                                  </div>
                                  <div className="success__single-text success__single-text2">
                                    {/* <span className="bdevs-el-list_subtitle">
                                      By Business Forum
                                    </span> */}
                                    <h5 className="success-title mb-15 bdevs-el-list_title">
                                      <a href="#">
                                        Our Vision
                                      </a>
                                    </h5>
                                    <p className="bdevs-el-list_description">
                                      To become the most reliable and preferred partner for businesses seeking manpower solutions, empowering them to achieve sustained success through the power of exceptional talent. Combining talented people with innovative companies, we can build a brighter future for everyone.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="business-quality wow fadeInUp"
                                data-wow-delay="0.3s"
                              >
                                <div className="success__single d-flex megamau-success-single-3 mb-30">
                                  <div className="success__single-icon2 mt-10 mr-35">
                                    <a href="#">
                                      <i
                                        aria-hidden="true"
                                        className=" flaticon-support"
                                      />{" "}
                                    </a>
                                  </div>
                                  <div className="success__single-text success__single-text2">
                                    {/* <span className="bdevs-el-list_subtitle">
                                      By Business Forum
                                    </span> */}
                                    <h5 className="success-title mb-15 bdevs-el-list_title">
                                      <a href="#">
                                        Why Choose Us?
                                      </a>
                                    </h5>
                                    <p className="bdevs-el-list_description">
                                      At Max Education, we don’t just provide manpower—we provide the right people to fuel your company’s success. Let us help you build a stronger, more capable workforce.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="business-quality wow fadeInUp"
                                data-wow-delay="0.3s"
                              >
                                <div className="success__single d-flex megamau-success-single-3 mb-30">
                                  <div className="success__single-icon2 mt-10 mr-35">
                                    <a href="#">
                                      <i
                                        className="bdevs-btn-icon  flaticon-worker"
                                        aria-hidden="true"
                                      />{" "}
                                    </a>
                                  </div>
                                  <div className="success__single-text success__single-text2">
                                    {/* <span className="bdevs-el-list_subtitle">
                                      By Business Forum
                                    </span> */}
                                    <h5 className="success-title mb-15 bdevs-el-list_title">
                                      <a href="#">
                                        Our Services
                                      </a>
                                    </h5>
                                    <p className="bdevs-el-list_description">
                                      At Max Education, we offer a broad range of manpower solutions to cater to the unique needs of various industries. Whether you're seeking technical expertise, skilled professionals, or unskilled labor, we have the right talent to support your business objectives. Our services include.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-1265f20 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="1265f20"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5009d4b"
              data-id="5009d4b"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-d612e17 elementor-widget elementor-widget-heading bdevs-el heading"
                  data-id="d612e17"
                  data-element_type="widget"
                  data-settings='{"design_style":"style_1"}'
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <div
                      className="section_title_wrapper mgsection_title-bottoms  wow fadeInUp"
                      data-wow-delay="0.3s"
                    >
                      <span className="subtitle bdevs-el-subtitle">
                        About Us{" "}
                      </span>
                      <h2 className="section-title section-title-black bdevs-el-title">
                        Empowering Workforce Solutions{" "}
                      </h2>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-da68681 elementor-widget elementor-widget-testimonial_slider bdevs-el testimonial_slider"
                  data-id="da68681"
                  data-element_type="widget"
                  data-settings='{"design_style":"style_1"}'
                  data-widget_type="testimonial_slider.default"
                >
                  <div className="elementor-widget-container">
                    {/* Client Reviews area start */}
                    <section className="client-reviews-area services-ex-bgg">
                      <div
                        className="client-active swiper-container"
                      // data-wow-delay="0.6s"
                      >
                        <div className="swiper-wrapper">
                          <div
                            className="client"
                            data-swiper-autoplay=""
                          >
                            <div className="testimonial-text mb-50 bdevs-el-content">
                              <p>
                                At Max Education, we believe that people are the backbone of every successful business, and meaningful, sustainable employment has the power to change the world.
                              </p>
                              <p>

                                As a leading provider of manpower solutions, we are dedicated to connecting businesses with the skilled and qualified talent they need to thrive in today’s dynamic marketplace. With years of industry experience, we understand the unique challenges that businesses face when it comes to finding the right workforce.

                              </p>
                            </div>
                            {/* <div className="testimonial-author d-flex align-items-center">
                              <div className="testimonial-author-icon mr-30">
                                <i className="fal fa-quote-left" />
                              </div>
                              <div className="testimonial-author-text">
                                <h5 className="uppercase bdevs-el-title">
                                  Jessica Brown
                                </h5>
                                <span className="bdevs-el-subtitle">
                                  House Holder
                                </span>
                              </div>
                            </div> */}
                          </div>
                          {/* <div
                            className="client swiper-slide"
                            data-swiper-autoplay=""
                          >
                            <div className="testimonial-text mb-50 bdevs-el-content">
                              <p>
                                Gravida augue erat nullam id tellus mattis vehicula
                                facilisic aliquet euismos litora ullamcorper morbina
                                suspendisse vitae vestibulum eros vitae. pede nullam
                                nullas ultricies corne mone molestie erate montes
                                adipiscing leoest magnis imperdiet fermentum auctor
                                hymenae laoren
                              </p>
                            </div>
                            <div className="testimonial-author d-flex align-items-center">
                              <div className="testimonial-author-icon mr-30">
                                <i className="fal fa-quote-left" />
                              </div>
                              <div className="testimonial-author-text">
                                <h5 className="uppercase bdevs-el-title">
                                  Jessica Brown
                                </h5>
                                <span className="bdevs-el-subtitle">
                                  House Holder
                                </span>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </section>
                    {/* Client Reviews area end */}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4e08ee4"
              data-id="4e08ee4"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-4b885c4 elementor-widget elementor-widget-fact bdevs-el fact"
                  data-id="4b885c4"
                  data-element_type="widget"
                  data-settings='{"design_style":"style_1"}'
                  data-widget_type="fact.default"
                >
                  <div className="elementor-widget-container">
                    {/* Client Reviews area start */}
                    <section className="client-reviews-area services-ex-bgg">
                      <div className="achievement-group">
                      <img
                                  decoding="async"
                                  src="assets/images/service_Photo/2.jpeg"
                                  alt="image not found"
                                  style={{
                                    height: 'auto'
                                     ,width: 'auto'
                                    // borderRadius: "50%", // Makes it circular
                                    // overflow: "hidden", // Ensures no content overflows
                                    // objectFit: "cover", // Ensures the image fits well
                                    // border: "2px solid #000", // Optional: Add a border
                                  }}
                                />
                        {/* <div
                          className="elementor-repeater-item-0313003 achievement-item mb-30 wow fadeInUp "
                          data-wow-delay="0.3s"
                        >
                          <ul className="achievement-item-box bdevs-el-content d-flex align-items-center">
                            <li className="bdevs-el-icon">
                              <figure>
                                <i
                                  aria-hidden="true"
                                  className=" flaticon-support"
                                />
                              </figure>
                            </li>
                            <li>
                              <h2 className="counter-count bdevs-el-title">
                                <span className="counter">120 </span>+
                              </h2>
                              <p className="counter-title">expert employee</p>
                            </li>
                          </ul>
                        </div>
                        <div
                          className="elementor-repeater-item-bb477f7 achievement-item mb-30 wow fadeInUp "
                          data-wow-delay="0.3s"
                        >
                          <ul className="achievement-item-box bdevs-el-content d-flex align-items-center">
                            <li className="bdevs-el-icon">
                              <figure>
                                <i aria-hidden="true" className=" flaticon-medal" />
                              </figure>
                            </li>
                            <li>
                              <h2 className="counter-count bdevs-el-title">
                                <span className="counter">325 </span>+
                              </h2>
                              <p className="counter-title">Completed Project</p>
                            </li>
                          </ul>
                        </div>
                        <div
                          className="elementor-repeater-item-e57e820 achievement-item mb-30 wow fadeInUp "
                          data-wow-delay="0.3s"
                        >
                          <ul className="achievement-item-box bdevs-el-content d-flex align-items-center">
                            <li className="bdevs-el-icon">
                              <figure>
                                <i
                                  aria-hidden="true"
                                  className=" flaticon-recovered"
                                />
                              </figure>
                            </li>
                            <li>
                              <h2 className="counter-count bdevs-el-title">
                                <span className="counter">235 </span>+
                              </h2>
                              <p className="counter-title">Happy customer</p>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    </section>
                    {/* Client Reviews area end */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-a190f38 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="a190f38"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-77ad68c"
              data-id="77ad68c"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-22c96bf elementor-widget elementor-widget-heading bdevs-el heading"
                  data-id="22c96bf"
                  data-element_type="widget"
                  data-settings='{"design_style":"style_2"}'
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <div
                      className="section_title_wrapper text-center wow fadeInUp"
                      data-wow-delay="0.3s"
                    >
                      <h2 className="msection-title bdevs-el-title">
                        Popular <span>Services</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-f7eba66 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="f7eba66"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-601ea6b"
              data-id="601ea6b"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-b5f31f0 elementor-widget elementor-widget-post_list bdevs-el post_list"
                  data-id="b5f31f0"
                  data-element_type="widget"
                  data-settings='{"design_style":"style_2"}'
                  data-widget_type="post_list.default"
                >
                  <div className="elementor-widget-container">
                    {/* mblog area start here */}
                    <div className="mblog-pd wow fadeInUp" data-wow-delay="0.3s">
                      <div className="mblog">
                        <div className="mblog__thumb p-relative">
                          <div className="mblog__thumb-img">
                            <a href="#">
                              <img
                                decoding="async"
                                src="assets/images/emp_image/trending-services-1.jpg"
                                alt="img not found"
                                style={{ height: '200px' }}
                              />
                            </a>
                          </div>
                          <div className="mblog__tag">
                            <a href="#">
                              {/* <i aria-hidden="true" className="far fa-folder-open" /> */}
                              IT Technical
                            </a>
                          </div>
                        </div>
                        <div className="mblog__content smblog__content bdevs-el-content" style={{ height: '150px' }}>
                          <h4 className="item_title bdevs-el-title mblog__title bdevs-el-title">
                            <a href="#">
                              Top IT Talent for Your Tech Needs
                            </a>
                          </h4>
                          {/* <div className="mblog__meta pt-10">
                      <span>
                        <i className="far fa-calendar-alt" />
                        Jan 1 /2022
                      </span>
                      <a href="index.html#respond">
                        <span>
                          <i className="far fa-comment" />
                          No Comments
                        </span>
                      </a>
                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-a8ed027"
              data-id="a8ed027"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-0745780 elementor-widget elementor-widget-post_list bdevs-el post_list"
                  // data-id={0745780}
                  data-element_type="widget"
                  data-settings='{"design_style":"style_2"}'
                  data-widget_type="post_list.default"
                >
                  <div className="elementor-widget-container">
                    {/* mblog area start here */}
                    <div className="mblog-pd wow fadeInUp" data-wow-delay="0.3s">
                      <div className="mblog">
                        <div className="mblog__thumb p-relative">
                          <div className="mblog__thumb-img">
                            <a href="#">
                              <img
                                decoding="async"
                                src="assets/images/emp_image/trending-services-2.jpg"
                                alt="img not found"
                                style={{ height: '200px' }}
                              />
                            </a>
                          </div>
                          <div className="mblog__tag">
                            <a href="#">
                              {/* <i aria-hidden="true" className="far fa-folder-open" /> */}
                              Non-IT Technical
                            </a>
                          </div>
                        </div>
                        <div className="mblog__content smblog__content bdevs-el-content">
                          <h4 className="item_title bdevs-el-title mblog__title bdevs-el-title">
                            <a href="#">
                              Specialized Non-IT Professionals for Various Industries
                            </a>
                          </h4>
                          {/* <div className="mblog__meta pt-10">
                      <span>
                        <i className="far fa-calendar-alt" />
                        Jan 1 /2022
                      </span>
                      <a href="#">
                        <span>
                          <i className="far fa-comment" />
                          No Comments
                        </span>
                      </a>
                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-905d7cb"
              data-id="905d7cb"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-4f33a63 elementor-widget elementor-widget-post_list bdevs-el post_list"
                  data-id="4f33a63"
                  data-element_type="widget"
                  data-settings='{"design_style":"style_2"}'
                  data-widget_type="post_list.default"
                >
                  <div className="elementor-widget-container">
                    {/* mblog area start here */}
                    <div className="mblog-pd wow fadeInUp" data-wow-delay="0.3s">
                      <div className="mblog">
                        <div className="mblog__thumb p-relative">
                          <div className="mblog__thumb-img">
                            <a href="#">
                              <img
                                decoding="async"
                                src="assets/images/emp_image/trending-services-6.jpeg"
                                alt="img not found"
                                style={{ height: '200px' }}
                              />
                            </a>
                          </div>
                          <div className="mblog__tag">
                            <a href="#">
                              {/* <i aria-hidden="true" className="far fa-folder-open" /> */}
                              Non-Technical Unskilled
                            </a>
                          </div>
                        </div>
                        <div className="mblog__content smblog__content bdevs-el-content">
                          <h4 className="item_title bdevs-el-title mblog__title bdevs-el-title">
                            <a href="">
                              Dependable General Labor for Smooth Operations
                            </a>
                          </h4>
                          {/* <div className="mblog__meta pt-10">
                      <span>
                        <i className="far fa-calendar-alt" />
                        Jan 1 /2022
                      </span>
                      <a href="#">
                        <span>
                          <i className="far fa-comment" />
                          No Comments
                        </span>
                      </a>
                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-905d7cb"
              data-id="905d7cb"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-4f33a63 elementor-widget elementor-widget-post_list bdevs-el post_list"
                  data-id="4f33a63"
                  data-element_type="widget"
                  data-settings='{"design_style":"style_2"}'
                  data-widget_type="post_list.default"
                >
                  <div className="elementor-widget-container">
                    {/* mblog area start here */}
                    <div className="mblog-pd wow fadeInUp" data-wow-delay="0.3s">
                      <div className="mblog">
                        <div className="mblog__thumb p-relative">
                          <div className="mblog__thumb-img">
                            <a href="#">
                              <img
                                decoding="async"
                                src="assets/images/emp_image/trending-services-5.jpeg"
                                alt="img not found"
                                style={{ height: '200px' }}
                              />
                            </a>
                          </div>
                          <div className="mblog__tag">
                            <a href="#">
                              {/* <i aria-hidden="true" className="far fa-folder-open" /> */}
                              Skilled Workforce
                            </a>
                          </div>
                        </div>
                        <div className="mblog__content smblog__content bdevs-el-content">
                          <h4 className="item_title bdevs-el-title mblog__title bdevs-el-title">
                            <a href="">
                              Qualified Skilled Trades for Quality Projects
                            </a>
                          </h4>
                          {/* <div className="mblog__meta pt-10">
                      <span>
                        <i className="far fa-calendar-alt" />
                        Jan 1 /2022
                      </span>
                      <a href="#">
                        <span>
                          <i className="far fa-comment" />
                          No Comments
                        </span>
                      </a>
                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-b419eac elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="b419eac"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1210183"
              data-id={1210183}
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-5019c5e elementor-widget elementor-widget-cf7 bdevs-el cf7"
                  data-id="5019c5e"
                  data-element_type="widget"
                  data-settings='{"design_style":"style_4"}'
                  data-widget_type="cf7.default"
                >
                  <div className="elementor-widget-container">
                    <div className="footter__top footer__top-3 footer-bgg">
                      <div className="container">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6">
                            <div
                              className="footer__subscribe-title wow fadeInUp"
                              data-wow-delay="0.3s"
                            >
                              <h2 className="big_title mb-0 msection-title mb-35 bdevs-el-title">
                                Subscribe Now
                              </h2>
                            </div>
                          </div>
                          <div className="col-xl-8">
                            <div
                              className="footer__subscribe wow fadeInUp"
                              data-wow-delay="0.6s"
                            >
                              <div className="footer__subscribe-form footer__subscribe-form-2 footer__subscribe-form-3 p-relative">
                                <div
                                  className="wpcf7 no-js"
                                  id="wpcf7-f1484-p162-o1"
                                  lang="en-US"
                                  dir="ltr"
                                >
                                  <div className="screen-reader-response">
                                    <p
                                      role="status"
                                      aria-live="polite"
                                      aria-atomic="true"
                                    />
                                    <ul />
                                  </div>
                                  <form
                                    action="https://codeskdhaka.com/wp/megamau/#wpcf7-f1484-p162-o1"
                                    method="post"
                                    className="wpcf7-form init bdevs-cf7-form"
                                    aria-label="Contact form"
                                    noValidate="novalidate"
                                    data-status="init"
                                  >
                                    <div style={{ display: "none" }}>
                                      <input
                                        type="hidden"
                                        name="_wpcf7"
                                        defaultValue={1484}
                                      />
                                      <input
                                        type="hidden"
                                        name="_wpcf7_version"
                                        defaultValue="5.9.8"
                                      />
                                      <input
                                        type="hidden"
                                        name="_wpcf7_locale"
                                        defaultValue="en_US"
                                      />
                                      <input
                                        type="hidden"
                                        name="_wpcf7_unit_tag"
                                        defaultValue="wpcf7-f1484-p162-o1"
                                      />
                                      <input
                                        type="hidden"
                                        name="_wpcf7_container_post"
                                        defaultValue={162}
                                      />
                                      <input
                                        type="hidden"
                                        name="_wpcf7_posted_data_hash"
                                        defaultValue=""
                                      />
                                    </div>
                                    <p>
                                      <span
                                        className="wpcf7-form-control-wrap"
                                        data-name="email"
                                      >
                                        <input
                                          size={40}
                                          maxLength={400}
                                          className="wpcf7-form-control wpcf7-email wpcf7-text wpcf7-validates-as-email"
                                          aria-invalid="false"
                                          placeholder="Email Address"
                                          defaultValue=""
                                          type="email"
                                          name="email"
                                        />
                                      </span>
                                      <br />
                                      <button className="theme-btn bdevs-el-btn">
                                        subscribe now
                                      </button>
                                    </p>
                                    <div
                                      className="wpcf7-response-output"
                                      aria-hidden="true"
                                    />
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <Footer /> */}
    </>

  );
}

export default Home;
