import React from "react";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import { useEffect } from 'react';
function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
    // Initialize PureCounter after the component is mounted
    if (window.PureCounter) {
      new window.PureCounter(); // Initialize the pure counter
    }

  }, []); // This runs only after the component is mounted

  return (
    <>
       <Header />
      <div
        className="page-title-area " style={{
          padding: '15px'
          , backgroundImage: "url('assets/wp-content/uploads/2022/01/download-17.jpg')", // Set the image URL
        }}

      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-title-wrapper text-center">
                <h3 className="page-title">About Us</h3>
                <div className="breadcrumb breadcrumb-list">
                  {/* Breadcrumb NavXT 7.3.1 */}
                  <span property="itemListElement" typeof="ListItem">
                    <a
                      property="item"
                      typeof="WebPage"
                      title="Go to Megamau."
                      href="https://codeskdhaka.com/wp/megamau"
                      className="home"
                    >
                      <span property="name">Home</span>
                    </a>
                    <meta property="position" content={1} />
                  </span>
                  <span className="dvdr">
                    <i className="far fa-angle-double-right" />
                  </span>
                  <span property="itemListElement" typeof="ListItem">
                    <span property="name" className="post post-page current-item">
                      About Us
                    </span>
                    <meta property="url" content="index.html" />
                    <meta property="position" content={2} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div
        data-elementor-type="wp-page"
        data-elementor-id={346}
        className="elementor elementor-346"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-ffe7c8d elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="ffe7c8d"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1e1079c"
              data-id="1e1079c"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-7708592 elementor-widget elementor-widget-about bdevs-el about"
                  data-id={7708592}
                  data-element_type="widget"
                  data-settings='{"design_style":"style_2"}'
                  data-widget_type="about.default"
                >
                  <div className="elementor-widget-container">
                    {/* about area start here */}
                    <section className="about-area">
                      <div className="container">
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6">
                            <div
                              className="mabout__thumb p-relative mb-50 wow fadeInUp"
                              data-wow-delay="0.3s"
                            >
                              {/* <div className="aabout__since d-flex align-items-center justify-content-center">
                                <div className="megamau-project-box">
                                  <h1 className="counter-count counter-count2">
                                    <span className="counter">500</span>+
                                  </h1>
                                  <h3>
                                    Project <br /> 
                                  </h3>
                                </div>
                              </div> */}
                              <div className="mabout__thumb-top mb-10">
                                <img
                                  decoding="async"
                                  src="assets/wp-content/uploads/2022/02/ab-1.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="mabout__thumb-btm">
                                <img
                                  decoding="async"
                                  src="assets/wp-content/uploads/2022/02/ab-2.jpg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6">
                            <div className="mabout-right bdevs-el-content mb-45">
                              <div
                                className="section_title_wrapper mb-35 wow fadeInUp"
                                data-wow-delay="0.3s"
                              >
                                <h2 className="msection-title mb-35 bdevs-el-title">
                                  Who <span>We</span> Are
                                </h2>
                                <div className="msubtitle">
                                  <p style={{ textAlign: 'justify' }}>
                                    Welcome to Max Education! We are a leading provider of manpower
                                    solutions across multiple industries, specializing in connecting
                                    businesses with top talent to meet their unique workforce needs.
                                    Whether it’s technical or non-technical roles,
                                    skilled or unskilled labor, we are committed
                                    to delivering exceptional solutions tailored to your business.
                                  </p>
                                </div>
                              </div>
                              <div className="mabout-quality">
                                <div className="mabout">
                                  <div className="megamau-mabout-repeater h-megamau-mabout-repeater-2">
                                    <div
                                      className="mabout__single d-flex align-items-center mb-35 wow fadeInUp elementor-repeater-item-fdf9234 "
                                      data-wow-delay="0.3s"
                                    >
                                      <div className="mabout__single-icon mr-30 ">
                                        <a className="" href="#">
                                          <i
                                            className="bdevs-btn-icon  flaticon-award"
                                            aria-hidden="true"
                                          />{" "}
                                        </a>
                                      </div>
                                      <div className="mabout__single-text">
                                        <h5 className="mabout-title mb-15 bdevs-el-list-title">
                                          <a href="#">Our Mission</a>
                                        </h5>
                                        <p style={{ textAlign: 'justify' }} className="bdevs-el-list-desc">
                                          <b>Our mission is simple:<br /></b>
                                          To become the most reliable and preferred partner for businesses seeking manpower solutions. By empowering businesses with exceptional talent, we aim to foster sustainable success and build a brighter future for everyone.
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      className="mabout__single d-flex align-items-center mb-35 wow fadeInUp elementor-repeater-item-f03b92a "
                                      data-wow-delay="0.3s"
                                    >
                                      <div className="mabout__single-icon mr-30 ">
                                        <a className="" href="#">
                                          <i
                                            className="bdevs-btn-icon  flaticon-technician"
                                            aria-hidden="true"
                                          />{" "}
                                        </a>
                                      </div>
                                      <div className="mabout__single-text">
                                        <h5 className="mabout-title mb-15 bdevs-el-list-title">
                                          <a href="#">What We Believe</a>
                                        </h5>
                                        <p style={{ textAlign: 'justify' }} className="bdevs-el-list-desc">
                                          <b>  At Max Education, we believe:<br /></b>

                                          <ul>
                                            <li><strong>People are the backbone</strong> of every successful business.</li>
                                            <li><strong>Meaningful and sustainable employment</strong> has the power to change the world.<br />
                                              We are passionate about connecting innovative companies with talented individuals,
                                              creating a win-win for businesses and job seekers alike.
                                            </li>
                                          </ul>

                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    {/* about area end here */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e490f32 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e490f32"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-14cfc32"
              data-id="14cfc32"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-9f47d75 elementor-widget elementor-widget-heading bdevs-el heading"
                  data-id="9f47d75"
                  data-element_type="widget"
                  data-settings='{"design_style":"style_2"}'
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <div
                      className="section_title_wrapper text-center wow fadeInUp"
                      data-wow-delay="0.3s"
                    >
                      <h2 className="msection-title bdevs-el-title">
                        What We <span>Offer</span>
                      </h2>
                      <span>We provide manpower solutions across four key categories:</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-d974bab elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="d974bab"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-extended">
            <div
              className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-d92a64f"
              data-id="d92a64f"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-1753702 elementor-widget elementor-widget-icon_box bdevs-el icon_box"
                  data-id={1753702}
                  data-element_type="widget"
                  data-settings='{"design_style":"style_2"}'
                  data-widget_type="icon_box.default"
                >
                  <div className="elementor-widget-container">
                    <div className="mgservices wow fadeInUp" data-wow-delay="0.3s">
                      <div className="mgservices__icon m_services_icon_height mb-35">
                        <a
                          href="assets/ourservices/chemical-industry/index.html"
                          className="msr-color2 icon"
                        >
                          <figure>
                            <i aria-hidden="true" className=" flaticon-protection" />
                          </figure>

                        </a>
                      </div>
                      <div className="mgservices__content transition-3">
                        <h3 className="mgservices__content-title title">
                          <a href="assets/ourservices/chemical-industry/index.html">
                            IT Technical
                          </a>
                        </h3>
                        <p className="content-desc">
                          Top IT talent for your tech needs.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-3ec84f7"
              data-id="3ec84f7"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-4a5d95c elementor-widget elementor-widget-icon_box bdevs-el icon_box"
                  data-id="4a5d95c"
                  data-element_type="widget"
                  data-settings='{"design_style":"style_2"}'
                  data-widget_type="icon_box.default"
                >
                  <div className="elementor-widget-container">
                    <div className="mgservices wow fadeInUp" data-wow-delay="0.3s">
                      <div className="mgservices__icon m_services_icon_height mb-35">
                        <a
                          href="assets/ourservices/chemical-industry/index.html"
                          className="msr-color2 icon"
                        >
                          <figure>
                            <i aria-hidden="true" className=" flaticon-support" />
                          </figure>

                        </a>
                      </div>
                      <div className="mgservices__content transition-3">
                        <h3 className="mgservices__content-title title">
                          <a href="assets/ourservices/chemical-industry/index.html">
                            Non-IT Technical
                          </a>
                        </h3>
                        <p className="content-desc">
                          Specialized professionals for various industries.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-1d97ab8"
              data-id="1d97ab8"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-416f4d9 elementor-widget elementor-widget-icon_box bdevs-el icon_box"
                  data-id="416f4d9"
                  data-element_type="widget"
                  data-settings='{"design_style":"style_2"}'
                  data-widget_type="icon_box.default"
                >
                  <div className="elementor-widget-container">
                    <div className="mgservices wow fadeInUp" data-wow-delay="0.3s">
                      <div className="mgservices__icon m_services_icon_height mb-35">
                        <a
                          href="assets/ourservices/chemical-industry/index.html"
                          className="msr-color2 icon"
                        >

                          <figure>
                            <i aria-hidden="true" className=" flaticon-recovered" />
                          </figure>
                        </a>
                      </div>
                      <div className="mgservices__content transition-3">
                        <h3 className="mgservices__content-title title">
                          <a href="assets/ourservices/chemical-industry/index.html">
                            Non-Technical Unskilled
                          </a>
                        </h3>
                        <p className="content-desc">
                          Dependable general labor for smooth operations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-71aae61"
              data-id="71aae61"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-4be85e0 elementor-widget elementor-widget-icon_box bdevs-el icon_box"
                  data-id="4be85e0"
                  data-element_type="widget"
                  data-settings='{"design_style":"style_2"}'
                  data-widget_type="icon_box.default"
                >
                  <div className="elementor-widget-container">
                    <div className="mgservices wow fadeInUp" data-wow-delay="0.3s">
                      <div className="mgservices__icon m_services_icon_height mb-35">
                        <a
                          href="assets/ourservices/chemical-industry/index.html"
                          className="msr-color2 icon"
                        >
                          <figure>
                            <i aria-hidden="true" className=" flaticon-medal" />
                          </figure>
                        </a>
                      </div>
                      <div className="mgservices__content transition-3">
                        <h3 className="mgservices__content-title title">
                          <a href="assets/ourservices/chemical-industry/index.html">
                            Skilled Workforce
                          </a>
                        </h3>
                        <p className="content-desc">
                          Qualified tradespeople for high-quality projects.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-f8f866d elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="f8f866d"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-extended">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5114b52"
              data-id="5114b52"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-df11d87 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="df11d87"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-default">
                    <div
                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-712a3ba"
                      data-id="712a3ba"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-5494409 elementor-widget elementor-widget-heading bdevs-el heading"
                          data-id={5494409}
                          data-element_type="widget"
                          data-settings='{"design_style":"style_2"}'
                          data-widget_type="heading.default"
                        >
                          <div className="elementor-widget-container">
                            <div
                              className="section_title_wrapper text-center wow fadeInUp"
                              data-wow-delay="0.3s"
                            >
                              <h2 className="msection-title bdevs-el-title">
                                Why <span>Choose</span> Us
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div
                  className="elementor-element elementor-element-8eb8a4a elementor-widget elementor-widget-member_slider bdevs-el member_slider"
                  data-id="8eb8a4a"
                  data-element_type="widget"
                  data-settings='{"design_style":"style_1"}'
                  data-widget_type="member_slider.default"
                >
                  <div className="elementor-widget-container">

                    {/* team area start here */}
                    <section className="mgteam-area">
                      <div className="container">
                        <div className="mabout-quality">
                          <div className="mabout">
                            <div className="megamau-mabout-repeater h-megamau-mabout-repeater-2">

                              <div
                                className="mabout__single d-flex align-items-center mb-35 wow fadeInUp elementor-repeater-item-f03b92a "
                                data-wow-delay="0.3s"
                              >

                                <div className="mabout__single-text">
                                  {/* <h5 className="mabout-title mb-15 bdevs-el-list-title">
                                    <a href="#">What We Believe</a>
                                  </h5> */}
                                  <p style={{ textAlign: 'justify' }} >
                                    <b> With years of industry experience, we understand the challenges of finding the right workforce. Our solutions are designed to make it easy for businesses to:<br /></b>

                                    <ul>
                                      <li>Hire temporary staff.</li>
                                      <li>Fill contractual placements. </li>
                                      <li>Access specialized professionals.</li>
                                    </ul>
                                    Our commitment to quality, integrity, and customer satisfaction sets us apart, ensuring that you receive not just workers but the right talent to help your business thrive.
                                  </p>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </section>
                    {/* team area end here */}
                    {/* style 2 */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-f8f866d elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="f8f866d"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-extended">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5114b52"
              data-id="5114b52"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-df11d87 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="df11d87"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-default">
                    <div
                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-712a3ba"
                      data-id="712a3ba"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-5494409 elementor-widget elementor-widget-heading bdevs-el heading"
                          data-id={5494409}
                          data-element_type="widget"
                          data-settings='{"design_style":"style_2"}'
                          data-widget_type="heading.default"
                        >
                          <div className="elementor-widget-container">
                            <div
                              className="section_title_wrapper text-center wow fadeInUp"
                              data-wow-delay="0.3s"
                            >
                              <h2 className="msection-title bdevs-el-title">
                                Our <span>Promise</span>
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div
                  className="elementor-element elementor-element-8eb8a4a elementor-widget elementor-widget-member_slider bdevs-el member_slider"
                  data-id="8eb8a4a"
                  data-element_type="widget"
                  data-settings='{"design_style":"style_1"}'
                  data-widget_type="member_slider.default"
                >
                  <div className="elementor-widget-container">

                    {/* team area start here */}
                    <section className="mgteam-area">
                      <div className="container">
                        <div className="mabout-quality">
                          <div className="mabout">
                            <div className="megamau-mabout-repeater h-megamau-mabout-repeater-2">

                              <div
                                className="mabout__single d-flex align-items-center mb-35 wow fadeInUp elementor-repeater-item-f03b92a "
                                data-wow-delay="0.3s"
                              >

                                <div className="mabout__single-text">
                                  {/* <h5 className="mabout-title mb-15 bdevs-el-list-title">
                                    <a href="#">What We Believe</a>
                                  </h5> */}
                                  <p style={{ textAlign: 'justify' }} >
                                    <b> When you partner with Max Education, you get:<br /></b>

                                    <ul>
                                      <li>Customized manpower solutions to meet your unique needs.</li>
                                      <li>A seamless hiring process designed for efficiency.</li>
                                      <li>A trusted partner committed to your success.</li>
                                    </ul>
                                    Let us help you build your dream team and achieve your business goals!
                                  </p>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </section>
                    {/* team area end here */}
                    {/* style 2 */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section
          className="elementor-section elementor-top-section elementor-element elementor-element-4c2a983 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="4c2a983"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3fac16e"
              data-id="3fac16e"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-8e5661f elementor-widget elementor-widget-cf7 bdevs-el cf7"
                  data-id="8e5661f"
                  data-element_type="widget"
                  data-settings='{"design_style":"style_4"}'
                  data-widget_type="cf7.default"
                >
                  <div className="elementor-widget-container">
                    <div className="footter__top footer__top-3 footer-bgg">
                      <div className="container">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6">
                            <div
                              className="footer__subscribe-title wow fadeInUp"
                              data-wow-delay="0.3s"
                            >
                              <h2 className="big_title mb-0 msection-title mb-35 bdevs-el-title">
                                Subscribe Now
                              </h2>
                            </div>
                          </div>
                          <div className="col-xl-8">
                            <div
                              className="footer__subscribe wow fadeInUp"
                              data-wow-delay="0.6s"
                            >
                              <div className="footer__subscribe-form footer__subscribe-form-2 footer__subscribe-form-3 p-relative">
                                <div
                                  className="wpcf7 no-js"
                                  id="wpcf7-f1484-p346-o1"
                                  lang="en-US"
                                  dir="ltr"
                                >
                                  <div className="screen-reader-response">
                                    <p
                                      role="status"
                                      aria-live="polite"
                                      aria-atomic="true"
                                    ></p>
                                    <ul />
                                  </div>
                                  <form
                                    action="https://codeskdhaka.com/wp/megamau/about-us/#wpcf7-f1484-p346-o1"
                                    method="post"
                                    className="wpcf7-form init bdevs-cf7-form"
                                    aria-label="Contact form"
                                    noValidate="novalidate"
                                    data-status="init"
                                  >
                                    <div style={{ display: "none" }}>
                                      <input
                                        type="hidden"
                                        name="_wpcf7"
                                        defaultValue={1484}
                                      />
                                      <input
                                        type="hidden"
                                        name="_wpcf7_version"
                                        defaultValue="5.9.8"
                                      />
                                      <input
                                        type="hidden"
                                        name="_wpcf7_locale"
                                        defaultValue="en_US"
                                      />
                                      <input
                                        type="hidden"
                                        name="_wpcf7_unit_tag"
                                        defaultValue="wpcf7-f1484-p346-o1"
                                      />
                                      <input
                                        type="hidden"
                                        name="_wpcf7_container_post"
                                        defaultValue={346}
                                      />
                                      <input
                                        type="hidden"
                                        name="_wpcf7_posted_data_hash"
                                        defaultValue=""
                                      />
                                    </div>
                                    <p>
                                      <span
                                        className="wpcf7-form-control-wrap"
                                        data-name="email"
                                      >
                                        <input
                                          size={40}
                                          maxLength={400}
                                          className="wpcf7-form-control wpcf7-email wpcf7-text wpcf7-validates-as-email"
                                          aria-invalid="false"
                                          placeholder="Email Address"
                                          defaultValue=""
                                          type="email"
                                          name="email"
                                        />
                                      </span>
                                      <br />
                                      <button className="theme-btn bdevs-el-btn">
                                        subscribe now
                                      </button>
                                    </p>
                                    <div
                                      className="wpcf7-response-output"
                                      aria-hidden="true"
                                    ></div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    </>

  );
}

export default About;
