import React from "react";
import Header from "./Common/Header";
import Footer from "./Common/Footer";

function Contact() {

    return (
        <>
            <Header />
            <div
                className="page-title-area " style={{
                    padding: '15px'
                    , backgroundImage: "url('assets/wp-content/uploads/2022/01/download-17.jpg')", // Set the image URL
                }}

            >
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="page-title-wrapper text-center">
                                <h3 className="page-title">About Us</h3>
                                <div className="breadcrumb breadcrumb-list">
                                    {/* Breadcrumb NavXT 7.3.1 */}
                                    <span property="itemListElement" typeof="ListItem">
                                        <a
                                            property="item"
                                            typeof="WebPage"
                                            title="Go to Megamau."
                                            href="https://codeskdhaka.com/wp/megamau"
                                            className="home"
                                        >
                                            <span property="name">Home</span>
                                        </a>
                                        <meta property="position" content={1} />
                                    </span>
                                    <span className="dvdr">
                                        <i className="far fa-angle-double-right" />
                                    </span>
                                    <span property="itemListElement" typeof="ListItem">
                                        <span property="name" className="post post-page current-item">
                                            Contact
                                        </span>
                                        <meta property="url" content="index.html" />
                                        <meta property="position" content={2} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div
                data-elementor-type="wp-page"
                data-elementor-id={1354}
                className="elementor elementor-1354"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-e57f0b6 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="e57f0b6"
                    data-element_type="section"
                >
                    <div className="elementor-container elementor-column-gap-default">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-28c94db"
                            data-id="28c94db"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div
                                    className="elementor-element elementor-element-fa84ce6 elementor-widget elementor-widget-google_maps"
                                    data-id="fa84ce6"
                                    data-element_type="widget"
                                    data-widget_type="google_maps.default"
                                >
                                    <div className="elementor-widget-container">
                                        <div className="elementor-custom-embed">
                                            <iframe
                                                loading="lazy"
                                                src="https://maps.google.com/maps?q=ParkRest&t=m&z=10&output=embed&iwloc=near"
                                                title="ParkRest"
                                                aria-label="ParkRest"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-9891fb8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="9891fb8"
                    data-element_type="section"
                >
                    <div className="elementor-container elementor-column-gap-default">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c7f37e3"
                            data-id="c7f37e3"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div
                                    className="elementor-element elementor-element-f3e8375 elementor-widget elementor-widget-cf7 bdevs-el cf7"
                                    data-id="f3e8375"
                                    data-element_type="widget"
                                    data-settings='{"design_style":"style_3"}'
                                    data-widget_type="cf7.default"
                                >
                                    <div className="elementor-widget-container">
                                        {/* contact area start here */}
                                        <section className="tp-contact-area">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-lg-10">
                                                        <div
                                                            className="section_title_wrapper mb-60 wow fadeInUp"
                                                            data-wow-delay="0.3s"
                                                        >
                                                            <span className="subtitle service-ex-subtitle"
                                                                style={{ textAlign: 'left' }}>
                                                                {/* GET FREE ESTIMATE{" "} */}
                                                            </span>
                                                            <br />
                                                            <h2 className="big_title mb-0 msection-title mb-35 bdevs-el-title"
                                                                style={{ textAlign: 'left' }}>
                                                                If You Have Any Query, Don’t Hesitate{" "}
                                                                <span>Contact </span>With Us
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <div className="row custom-mar-20">
                                                            <div className="col-lg-12 col-md-4 col-sm-6 custom-pad-20">
                                                                <div
                                                                    className="tp-contact-info mb-40 wow fadeInUp"
                                                                    data-wow-delay=".4s"
                                                                >
                                                                    <div className="tp-contact-info-icon">
                                                                        <i
                                                                            className="bdevs-btn-icon  flaticon-location"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </div>
                                                                    <div className="tp-contact-info-text">
                                                                        <h4 className="tp-contact-info-title mb-15 bdevs-el-title" style={{ textAlign: 'left' }}>
                                                                            Address
                                                                        </h4>
                                                                        <p style={{ textAlign: 'left' }}>
                                                                            A306, park rest society, behind <br />
                                                                            l&t knowledge city wagoriya,
                                                                            <br /> Vadodara(390019).
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-4 col-sm-6 custom-pad-20">
                                                                <div
                                                                    className="tp-contact-info mb-40 wow fadeInUp"
                                                                    data-wow-delay=".4s"
                                                                >
                                                                    <div className="tp-contact-info-icon">
                                                                        <i
                                                                            className="bdevs-btn-icon  flaticon-phone-call"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </div>
                                                                    <div className="tp-contact-info-text">
                                                                        <h4 className="tp-contact-info-title mb-15 bdevs-el-title" style={{ textAlign: 'left' }}>
                                                                            Phone
                                                                        </h4>
                                                                        <p style={{ textAlign: 'left' }}>
                                                                            +91 9099966379
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-4 col-sm-6 custom-pad-20">
                                                                <div
                                                                    className="tp-contact-info mb-40 wow fadeInUp"
                                                                    data-wow-delay=".4s"
                                                                >
                                                                    <div className="tp-contact-info-icon">
                                                                        <i
                                                                            className="bdevs-btn-icon  flaticon-letter"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </div>
                                                                    <div className="tp-contact-info-text">
                                                                        <h4 className="tp-contact-info-title mb-15 bdevs-el-title" style={{ textAlign: 'left' }}>
                                                                            Email
                                                                        </h4>
                                                                        <p style={{ textAlign: 'left' }}>
                                                                            amosay08@hotmail.com
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <div
                                                            className="wpcf7 no-js"
                                                            id="wpcf7-f1397-p1354-o1"
                                                            lang="en-US"
                                                            dir="ltr"
                                                        >
                                                            <div className="screen-reader-response">
                                                                <p
                                                                    role="status"
                                                                    aria-live="polite"
                                                                    aria-atomic="true"
                                                                />
                                                                <ul />
                                                            </div>
                                                            <form
                                                                action="https://codeskdhaka.com/wp/megamau/contact/#wpcf7-f1397-p1354-o1"
                                                                method="post"
                                                                className="wpcf7-form init bdevs-cf7-form"
                                                                aria-label="Contact form"
                                                                noValidate="novalidate"
                                                                data-status="init"
                                                            >
                                                                <div style={{ display: "none" }}>
                                                                    <input
                                                                        type="hidden"
                                                                        name="_wpcf7"
                                                                        defaultValue={1397}
                                                                    />
                                                                    <input
                                                                        type="hidden"
                                                                        name="_wpcf7_version"
                                                                        defaultValue="5.9.8"
                                                                    />
                                                                    <input
                                                                        type="hidden"
                                                                        name="_wpcf7_locale"
                                                                        defaultValue="en_US"
                                                                    />
                                                                    <input
                                                                        type="hidden"
                                                                        name="_wpcf7_unit_tag"
                                                                        defaultValue="wpcf7-f1397-p1354-o1"
                                                                    />
                                                                    <input
                                                                        type="hidden"
                                                                        name="_wpcf7_container_post"
                                                                        defaultValue={1354}
                                                                    />
                                                                    <input
                                                                        type="hidden"
                                                                        name="_wpcf7_posted_data_hash"
                                                                        defaultValue=""
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="tp-contact-form wow fadeInUp"
                                                                    data-wow-delay=".6s"
                                                                >
                                                                    <div className="row custom-mar-20">
                                                                        <div className="col-md-6 custom-pad-20">
                                                                            <div className="tp-contact-form-field mb-20">
                                                                                <p>
                                                                                    <span
                                                                                        className="wpcf7-form-control-wrap"
                                                                                        data-name="text"
                                                                                    >
                                                                                        <input
                                                                                            size={40}
                                                                                            maxLength={400}
                                                                                            className="wpcf7-form-control wpcf7-text"
                                                                                            aria-invalid="false"
                                                                                            placeholder="Full name"
                                                                                            defaultValue=""
                                                                                            type="text"
                                                                                            name="text"
                                                                                        />
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 custom-pad-20">
                                                                            <div className="tp-contact-form-field mb-20">
                                                                                <p>
                                                                                    <span
                                                                                        className="wpcf7-form-control-wrap"
                                                                                        data-name="email"
                                                                                    >
                                                                                        <input
                                                                                            size={40}
                                                                                            maxLength={400}
                                                                                            className="wpcf7-form-control wpcf7-email wpcf7-text wpcf7-validates-as-email"
                                                                                            aria-invalid="false"
                                                                                            placeholder="Email Address"
                                                                                            defaultValue=""
                                                                                            type="email"
                                                                                            name="email"
                                                                                        />
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 custom-pad-20">
                                                                            <div className="tp-contact-form-field mb-20">
                                                                                <p>
                                                                                    <span
                                                                                        className="wpcf7-form-control-wrap"
                                                                                        data-name="text"
                                                                                    >
                                                                                        <input
                                                                                            size={40}
                                                                                            maxLength={400}
                                                                                            className="wpcf7-form-control wpcf7-text"
                                                                                            aria-invalid="false"
                                                                                            placeholder="Phone"
                                                                                            defaultValue=""
                                                                                            type="text"
                                                                                            name="text"
                                                                                        />
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 custom-pad-20 mb-20">
                                                                            <div className="tp-contact-form-field select-field-arrow ">
                                                                                <p>
                                                                                    <span
                                                                                        className="wpcf7-form-control-wrap"
                                                                                        data-name="choosesubject"
                                                                                    >
                                                                                        <select
                                                                                            className="wpcf7-form-control wpcf7-select"
                                                                                            aria-invalid="false"
                                                                                            name="choosesubject"
                                                                                        >
                                                                                            <option value="IT Technical">
                                                                                                IT Technical
                                                                                            </option>
                                                                                            <option value="Non-IT Technical">
                                                                                                Non-IT Technical
                                                                                            </option>
                                                                                            <option value="Non-Technical Unskilled">
                                                                                                Non-Technical Unskilled
                                                                                            </option>
                                                                                            <option value="Skilled Workforce">
                                                                                                Skilled Workforce
                                                                                            </option>
                                                                                        </select>
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12 custom-pad-20">
                                                                            <div className="tp-contact-form-field mb-20">
                                                                                <p>
                                                                                    <span
                                                                                        className="wpcf7-form-control-wrap"
                                                                                        data-name="messages"
                                                                                    >
                                                                                        <textarea
                                                                                            cols={40}
                                                                                            rows={10}
                                                                                            maxLength={2000}
                                                                                            className="wpcf7-form-control wpcf7-textarea"
                                                                                            aria-invalid="false"
                                                                                            placeholder="Your Message"
                                                                                            name="messages"
                                                                                            defaultValue={""}
                                                                                            style={{ height: '120px' }}
                                                                                        />
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12 custom-pad-20">
                                                                            <div className="tp-contact-form-field">
                                                                                <p>
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="theme-btn home2-btn btn-before bdevs-el-btn"
                                                                                    >
                                                                                        <i className="fal fa-angle-double-right" />
                                                                                        Send Message
                                                                                    </button>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="wpcf7-response-output"
                                                                    aria-hidden="true"
                                                                />
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        {/* contact area end here */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

        </>

    );
}

export default Contact;
