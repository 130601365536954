import React from "react";
import { Link } from "react-router-dom";
function Header() {

    return (
        <>
            <header className="header-area2">
                {/* <div class="mheader-top theme-bg-blue">
<div class="container pt-10 pb-10">
  <div class="row align-items-center">
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
      <div class="header-top-left mheader-top-left">
        <span>Follow us:</span>
        <a href="#"><span><i class="fab fa-facebook-f"></i></span></a>


        <a href="#"><span><i class="fab fa-instagram"></i></span></a>

        <a href="#"><span><i class="fab fa-linkedin"></i></span></a>

        <a href="#"><span><i class="fab fa-youtube"></i></span></a>

      </div>
    </div>
    <div class="col-xl-9 col-lg-9 col-md-6 col-sm-6">
      <div class="main-menu-wrapper main-menu-wrapper2 d-flex align-items-center justify-content-end">

        <div class="main-menu-wrapper__search  text-left">
          <a class="search-btn nav-search 
                                      search-trigger main-menu-wrapper__search2" href="#">
            <i class="fal fa-search"></i>
            Search Here </a>
        </div>

        <div class="header__lang">
          <select>
            <option>English</option>
            <option>Bangla</option>
            <option>Franch</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>
    </div> */}
                <div className="mheader-bottom header-menu header-sticky sticky2-bg">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-3 col-lg-2 col-6">
                                <div className="logo-1">
                                    <a className="standard-logo" href="/">
                                        <img
                                            src="assets/wp-content/themes/megamau/assets/img/logo/logo-black.png"
                                            alt="logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-md-8 d-none d-lg-block">
                                <div className="main-menu main-menu-1-1 " id="white-menu">
                                    <nav id="mobile-menu">
                                        <ul id="menu-main-menu" className="">
                                            <li
                                                itemScope="itemscope"
                                                itemType="/"
                                                id="menu-item-1590"
                                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1590 nav-item"
                                            >
                                                <Link to="/" className="nav-link">Home</Link>
                                                {/* <a title="Home" href="/" className="nav-link">
                                                    Home
                                                </a> */}
                                            </li>
                                            <li
                                                itemScope="itemscope"
                                                itemType="https://www.schema.org/SiteNavigationElement"
                                                id="menu-item-1590"
                                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1590 nav-item"
                                            >
                                                <Link to="/about-us" className="nav-link">About Us</Link>
                                                {/* <a title="about-us" href="/about-us" className="nav-link">
                                                    About Us
                                                </a> */}
                                            </li>
                                            <li
                                                itemScope="itemscope"
                                                itemType="https://www.schema.org/SiteNavigationElement"
                                                id="menu-item-1839"
                                                className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-771 current_page_item current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor menu-item-has-children dropdown has-dropdown active menu-item-1839 nav-item"
                                            >
                                                <a title="Service"  className="nav-link">
                                                    Service
                                                </a>
                                                <ul className="submenu" role="menu">
                                                    <li
                                                        itemScope="itemscope"
                                                        itemType="https://www.schema.org/SiteNavigationElement"
                                                        id="menu-item-1596"
                                                        className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-771 current_page_item active menu-item-1596 nav-item"
                                                    >
                                                        <a
                                                            title="Service"
                                                            href="#"
                                                            className="dropdown-items"
                                                        >
                                                            Service
                                                        </a>
                                                    </li>
                                                    <li
                                                        itemScope="itemscope"
                                                        itemType="https://www.schema.org/SiteNavigationElement"
                                                        id="menu-item-1840"
                                                        className="menu-item menu-item-type-post_type menu-item-object-bdevs-services menu-item-1840 nav-item"
                                                    >
                                                        <a
                                                            title="Service Details"
                                                            href="#"
                                                            className="dropdown-items"
                                                        >
                                                            Service Details
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li
                                                itemScope="itemscope"
                                                itemType="https://www.schema.org/SiteNavigationElement"
                                                id="menu-item-1592"
                                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1592 nav-item"
                                            >
                                                <Link to="/contact" className="nav-link"> Contact</Link>
                                                {/* <a title="Contact" href="/contact" className="nav-link">
                                                    Contact
                                                </a> */}
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-2 col-6 d-none d-lg-block">
                                <div className="megamau-quote-btn text-end">
                                    <a href="/" className="megamau-header-btn-1">
                                        Get A Quote
                                    </a>
                                </div>
                            </div>
                            <div className="col-6 d-lg-none">
                                <div className="text-end">
                                    <a className="sidebar-toggle" href="javascript:void(0)">
                                        <i className="fas fa-chess-board" />{" "}
                                        <bdi className="d-none d-sm-inline-block">Menu</bdi>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="offcanvas-overlay" />
            </header>
            {/* header area end here */}
            <div className="offcanvas-overlay" />
            {/* sidebar area start */}
            <div className="sidebar__area">
                <div className="sidebar__wrapper">
                    <div className="sidebar__top d-flex align-items-center mb-40 justify-content-between">
                        <div className="logo">
                            <a href="/">
                                <img
                                    src="assets/wp-content/themes/megamau/assets/img/logo/logo-black.png"
                                    alt="Side Logo"
                                />
                            </a>
                        </div>
                        <div className="sidebar__close ml-50">
                            <button className="sidebar__close-btn sidebar-close">
                                <svg viewBox="0 0 22 22">
                                    <path
                                        d="M12.41,11l5.29-5.29c0.39-0.39,0.39-1.02,0-1.41s-1.02-0.39-1.41,0L11,9.59L5.71,4.29c-0.39-0.39-1.02-0.39-1.41,0
                      s-0.39,1.02,0,1.41L9.59,11l-5.29,5.29c-0.39,0.39-0.39,1.02,0,1.41C4.49,17.9,4.74,18,5,18s0.51-0.1,0.71-0.29L11,12.41l5.29,5.29
                      C16.49,17.9,16.74,18,17,18s0.51-0.1,0.71-0.29c0.39-0.39,0.39-1.02,0-1.41L12.41,11z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="sidebar__content p-relative z-index-1">
                        <div className="sidebar__menu">
                            <div className="mobile-menu-2 mean-container"></div>
                        </div>
                        <div className="contact-infos mb-30">
                            {/* <div class="contact-list">
      <h4>Contact Info</h4>
      <ul>
        <li><i class="flaticon-location"></i>28/4 Palmal, London</li>
        <li><i class="flaticon-letter"></i><a href="mailto:http://info@mega.com">info@mega.com</a>
        </li>
        <li><i class="flaticon-phone-call"></i><a href="tel:http://02%20(205)%20555%202255">02 (205)
            555 2255</a></li>
      </ul>
      <div class="sidebar__menu--social">
        <a href="#"><span><i class="fab fa-facebook-f"></i></span></a>


        <a href="#"><span><i class="fab fa-instagram"></i></span></a>

        <a href="#"><span><i class="fab fa-linkedin"></i></span></a>

        <a href="#"><span><i class="fab fa-youtube"></i></span></a>

      </div>
    </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;
